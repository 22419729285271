import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import RightSide from '../components/rightside-general'

import BG from '../images/awards-bg.jpg'

class Disclaimer extends React.Component {
	render() {
		const siteTitle = 'Disclaimer'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Disclaimer</h3>
								<p>
									Every effort has been made to offer the most current, correct
									and clearly expressed information possible within this site.
									Nonetheless, inadvertent errors can occur and applicable laws,
									rules and regulations may change.
								</p>
								<p>
									The information contained in this site is general and is not
									intended to serve as advice. No warranty is given in relation
									to the accuracy or reliability of any information. Users
									should not act or fail to act on the basis of information
									contained herein. Users are encouraged to contact WSC Group
									for advice concerning specific matters before making any
									decision .
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default Disclaimer

export const DisclaimerPageQuery = graphql`
	query DisclaimerPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
